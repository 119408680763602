import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BillingDetails } from 'src/app/models/billing-details';
import { LoggedUser } from 'src/app/models/logged-user';
import { BusinessService } from 'src/app/services/business.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'billing-form',
  templateUrl: './billing-form.component.html',
  styleUrls: ['./billing-form.component.css']
})
export class BillingFormComponent implements OnInit {

  constructor(
    public subscriptionService: SubscriptionService,
    private _formBuiler: UntypedFormBuilder,
    public _sharedDataService: SharedDataService,
    private _toast: ToasterService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private _businessService: BusinessService,
  ) { 
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/svg/edit.svg'
      )
    );
  }

  @Output() defaultBilling = new EventEmitter<BillingDetails>();
  user: LoggedUser = new LoggedUser(JSON.parse(localStorage.getItem('user')!));
  billingDetails: BillingDetails[] = this.subscriptionService.billingDetails;
  isBillingForm: string = 'show';
  billingForm = this._formBuiler.group({
    company_name: ['', [Validators.required]],
    address: ['', [Validators.required]],
    state: ['', [Validators.required]],
    city: ['', [Validators.required]],
    pincode: ['', [Validators.required]],
    country: ['', [Validators.required]],
    gstin: ['', [Validators.minLength(15),Validators.maxLength(15)]],
  });

  countrySearchTag: string = '';
  stateSearchTag: string = '';
  citySearchTag: string = '';
  loaderBillingDetails: boolean = false;
  defaultBillingDetails!: BillingDetails;
  billingCountryCode: string = '';
  noGstEnabled: boolean = false;

  ngOnInit(): void {
    this.noGstEnabled = this._sharedDataService.business?.dont_have_gst_no;
    this._sharedDataService.getApiCountryList();
    this._sharedDataService.getApiStateList();
    this.subscriptionService.getBillingDetails().subscribe({
      next: resp => {
        if(resp.body.code === 200) {
          let responseList = resp.body.data;
          this.subscriptionService.billingDetails = [];
          responseList?.forEach((element: any) => {
            this.subscriptionService.billingDetails.push(new BillingDetails(element));
          });
          this.billingDetails = this.subscriptionService.billingDetails;
          if(this.billingDetails?.length === 0) this.isBillingForm = 'empty';
          else {
            this.billingDetails?.forEach((element) => {
              if(element.default) {
                this.defaultBillingDetails = element;
                this.defaultBilling.emit(this.defaultBillingDetails);
                localStorage.setItem('country',this.defaultBillingDetails.country);
                this._sharedDataService.businessCountry = this.defaultBillingDetails.country;
              }
            })
          }
        }
        console.log(resp);
      },
      error: error => {
        console.log(error);
        this.isBillingForm = 'empty';
      }
    })
  }

  focusSearch() {
    document.getElementById('search')?.focus();
  }

  saveBilling() {
    if(this.defaultBillingDetails?.id) this.updateBillingDetails();
    else this.saveBillingInfo();
  }

  saveBillingInfo() {
    this._businessService.updateBusiness({dont_have_gst_no: this.noGstEnabled}).subscribe({
      next: resp => {
        this._sharedDataService.business.dont_have_gst_no = this.noGstEnabled;
      }
    })
    this.loaderBillingDetails = true;
    let input = {
      billing_name: this.billingForm.controls['company_name']?.value,
      gst_no: (this.billingForm.controls['gstin']?.value && this.billingForm.controls['gstin']?.value !== '') ? this.billingForm.controls['gstin']?.value : null,
      address_line_1: this.billingForm.controls['address']?.value,
      zip: this.billingForm.controls['pincode']?.value,
      state: this.billingForm.controls['state']?.value,
      city: this.billingForm.controls['city']?.value,
      country: this.billingForm.controls['country']?.value,
      default: true,
    };
    this.subscriptionService.addBillingDetails(input).subscribe({
      next: resp => {
        if(resp.body.code === 201) {
          this.defaultBillingDetails = new BillingDetails(resp.body.data);
          this.billingDetails.push(this.defaultBillingDetails);
          this.defaultBilling.emit(this.defaultBillingDetails);
          this.isBillingForm = 'show';
          localStorage.setItem('country',input.country);
          this._toast.success(resp.body.message);
        } else {
          this._toast.error(resp.body.message);
        }
        this.loaderBillingDetails = false;
      }, error: error => { this.loaderBillingDetails = false }
    })
  }

  editBillingDetails() {
    this.billingForm.controls['company_name']?.setValue(this.defaultBillingDetails?.billing_name);
    this.billingForm.controls['gstin']?.setValue(this.defaultBillingDetails?.gst_no);
    this.billingForm.controls['address']?.setValue(this.defaultBillingDetails?.address_line_1);
    this.billingForm.controls['pincode']?.setValue(this.defaultBillingDetails?.zip);
    this.billingForm.controls['state']?.setValue(this.defaultBillingDetails?.state);
    this.billingForm.controls['city']?.setValue(this.defaultBillingDetails?.city);
    this.billingForm.controls['country']?.setValue(this.defaultBillingDetails?.country);
    this.isBillingForm = 'edit';
  }

  updateBillingDetails() {
    this._businessService.updateBusiness({dont_have_gst_no: this.noGstEnabled}).subscribe({
      next: resp => {
        this._sharedDataService.business.dont_have_gst_no = this.noGstEnabled;
      }
    })
    this.loaderBillingDetails = true;
    let input = {
      billing_name: this.billingForm.controls['company_name']?.value,
      gst_no: (this.billingForm.controls['gstin']?.value && this.billingForm.controls['gstin']?.value !== '') ? this.billingForm.controls['gstin']?.value : null,
      address_line_1: this.billingForm.controls['address']?.value,
      zip: this.billingForm.controls['pincode']?.value,
      state: this.billingForm.controls['state']?.value,
      city: this.billingForm.controls['city']?.value,
      country: this.billingForm.controls['country']?.value,
      default: true,
    };
    this.subscriptionService.updateBillingDetails(input,this.defaultBillingDetails.id).subscribe({
      next: resp => {
        if(resp.body.code === 200) {
          this.defaultBillingDetails = new BillingDetails(resp.body.data);
          this.billingDetails = [];
          this.billingDetails.push(this.defaultBillingDetails);
          this.defaultBilling.emit(this.defaultBillingDetails);
          localStorage.setItem('country',input.country);
          this.isBillingForm = 'show';
          this._toast.success(resp.body.message);
        } else {
          this._toast.error(resp.body.message);
        }
        this.loaderBillingDetails = false;
      }, error: error => { this.loaderBillingDetails = false }
    })
  }

  setBillingCountry(country: any) {
    this.billingCountryCode = country.iso2;
    this.billingForm.controls['country'].setValue(country.name);
    this._sharedDataService.getApiStateList(country.iso2);
    this._sharedDataService.getApiCityList(country.iso2);
  }

  setBillingState(state: any) {
    this.billingForm.controls['state'].setValue(state.name);
    if(this.billingCountryCode !== '') {
      this._sharedDataService.getApiCityList(this.billingCountryCode ,state.iso2);
    }
  }

}
